html,body {
  font-family: 'Open Sans';
}

body {
  background-color:	rgba(221, 245, 202, 0.89);
}

.navbar-item-banner img {
  max-height: unset;
}

.tabs li.is-active a {
  border-bottom-color: #29590c;
  color: #29590c;
}

.button.is-info.is-outlined {

  background-color: transparent;
  border-color: #29590c;
  color: #29590c;
}

.button.is-info.is-outlined:focus, .button.is-info.is-outlined:hover {
  background-color: #29590c;
  border-color: #29590c;
  color: #fff;
}
